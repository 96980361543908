'use client';

import React, { useEffect } from 'react';

import Grid from '@gds/Grid/Grid';
import { Typography } from '@gds/Typography/Typography';
import { FullWidth } from '@page-builder/Components/FullWidth/FullWidth';

import { hasSetCookiesAtom } from '@tracking/Atoms/HasSetCookies.atom';
import { useAtomValue } from 'jotai';
import Image from 'next/image';
import Script from 'next/script';

import styles from './FrValuationHero.module.css';

interface Props {
  backgroundColor: string;
  image: Array<string>;
  header: string;
  subheader: string;
}

const IFrameHero: React.FC<Props> = ({ backgroundColor, image, header, subheader }) => {
  const ImageWrapper = 'span';
  const wrapperStyle = {
    background: backgroundColor,
  };
  const hasSetCookies = useAtomValue(hasSetCookiesAtom);

  useEffect(() => {
    window.gdprConsent = function () {
      if (hasSetCookies) {
        return ['essential', 'analytical', 'marketing'];
      } else return [];
    };

    const handleDocumentReadyStateChange = () => {
      if (document.readyState === 'complete') {
        const apiKey = 'wl-partner-009-external-fr-prod-3735fcb8b995482a';
        WkdaPartner.init(apiKey, { locale: 'fr-FR' });
      }
    };
    document.onreadystatechange = handleDocumentReadyStateChange;
    return () => {
      document.onreadystatechange = null;
    };
  }, []);

  return (
    <>
      <FullWidth>
        <div style={wrapperStyle} id="valuation-hero">
          <Grid.Container className={styles.heroWrapper}>
            <div className={styles.textWrapper}>
              <Typography className={styles.heading} variant="h1">
                <span>{header}</span>
              </Typography>
              <div className={styles.mobileImageContainer}>
                <Image src={image[0]} alt="Photo of a car" width={800} height={80} />
              </div>
              <Typography className={styles.subHeading} variant="h5">
                <span dangerouslySetInnerHTML={{ __html: subheader }} />
              </Typography>
            </div>
            <ImageWrapper className={styles.imageWrapper}>
              <div className={styles.imageContainer}>
                <Image src={image[0]} alt="Photo of a car" width={800} height={800} />
              </div>
            </ImageWrapper>
          </Grid.Container>
        </div>
      </FullWidth>
      <div className={styles.formWrapper}>
        <div id="wkda-funnel-container"></div>
        <Script
          src="https://static.wirkaufendeinauto.de/wkda-frontend/partner-funnel-iframe-manager/index.latest.js"
          strategy="afterInteractive"
          onLoad={() => {
            const apiKey = 'wl-partner-009-external-fr-prod-3735fcb8b995482a';
            window.WkdaPartner.init(apiKey, { locale: 'fr-FR' });
          }}
        />
      </div>
    </>
  );
};

export default IFrameHero;
