'use client';
import { useCallback } from 'react';

import { formStateAtom } from '@core/Atoms/Pdp/FormState.atom';
import { searchParamsAtom } from '@core/Atoms/SearchParams/SearchParams.atom';
import { userIdAtom } from '@core/Atoms/User/UserId.atom';
import { useModal } from '@core/Components/Modal/UseModal';
import Grid from '@gds/Grid/Grid';
import { FullWidth } from '@page-builder/Components/FullWidth/FullWidth';
import { RichText } from '@page-builder/Components/RichText/RichText';
import { useAtom, useAtomValue } from 'jotai';
import { useRouter } from 'next/navigation';

import { locationAtom } from 'Atoms/Search/PostcodeAtom/LocationAtom';
import { postcodeAtom } from 'Atoms/Search/PostcodeAtom/PostcodeAtom';

import { Campaign } from 'Entities/HeroCampaignBanner.entity';

import { enrichPlpPath, trackClick } from '../CampaignPromo.utils';

import { HeroCampaignBannerModal } from './HeroCampaignBannerModal';

import styles from './HeroCampaignBanner.module.css';

export const HeroCampaignBanner = ({
  locale,
  colourScheme,
  fullText,
  isActive,
  shortText,
  text,
  title,
  deepBanner,
  url,
  bannerLoc,
}: Campaign) => {
  const router = useRouter();
  const { openModal } = useModal();
  const shouldShowBanner = isActive[0].codename === 'true';
  const isDeepDisplay = deepBanner[0]?.codename === 'true';
  const color = colourScheme[0].codename;
  const pcAtom = useAtom(postcodeAtom);
  const location = useAtomValue(locationAtom);
  const userId = useAtomValue(userIdAtom);
  const searchParams = useAtomValue(searchParamsAtom);
  const formState = useAtomValue(formStateAtom);
  const handleClick = useCallback(() => {
    const trimUrl = url.trim();

    if (trimUrl) {
      const plpPath = enrichPlpPath(trimUrl, pcAtom, location, locale);

      trackClick(
        {
          type: 'banner',
          pathWithLocale: plpPath.split('?')[0],
          text: bannerLoc === 'leasing page' ? text : `${trimUrl} hero campaign banner`,
          location: bannerLoc,
        },
        {
          searchParams,
          userId,
          formState,
        },
      );

      router.push(plpPath);
      return;
    }
    openModal(<HeroCampaignBannerModal content={{ color, shortText, fullText }} />, {
      header: title,
    });
  }, [
    url,
    pcAtom[0],
    pcAtom[1],
    locale,
    location,
    color,
    shortText,
    fullText,
    title,
    router,
    openModal,
  ]);

  if (!shouldShowBanner) return null;

  return (
    <FullWidth>
      <div
        className={`${styles.wrapper} ${isDeepDisplay ? styles.deep : ''}`}
        data-color={color}
        onClick={handleClick}
      >
        <Grid.Container>{text && <RichText richText={text} />}</Grid.Container>
      </div>
    </FullWidth>
  );
};

HeroCampaignBanner.displayName = 'HeroCampaignBanner';
