'use client';

import { useEffect, useState } from 'react';

import { isModalOpenAtom } from '@core/Atoms/Modal/Modal.atom';
import { tenantAtom } from '@core/Atoms/Tenant/Tenant.atom';
import { TickSuccess } from '@gds/Icons/Paths/TickSuccess';
import { Typography } from '@gds/Typography/Typography';
import { isMobileAppAtom } from '@mobile-app/Atoms/IsMobileApp.atom';
import { useAtom, useAtomValue } from 'jotai';
import Image from 'next/image';

import { useRouter } from 'next/navigation';

import { useInitialMount } from 'Hooks/UseInitialMount';

import styles from './MotorwayModal.module.css';

interface MotorwayModalProps {
  motorwayRedirectTimerCopy?: string;
  motorwayRedirectSubheader?: string;
  motorwayRedirectUspOne?: string;
  motorwayRedirectUspTwo?: string;
  motorwayRedirectUspThree?: string;
  motorwayRedirectErrorMessage?: string;
  motorwayRedirectLogo?: Array<string>;
  reg?: string;
  modalKey?: number;
}

export const MotorwayModal = ({
  motorwayRedirectTimerCopy,
  motorwayRedirectSubheader,
  motorwayRedirectUspOne,
  motorwayRedirectUspTwo,
  motorwayRedirectUspThree,
  motorwayRedirectErrorMessage,
  motorwayRedirectLogo,
  reg,
  modalKey,
}: MotorwayModalProps) => {
  const tenant = useAtomValue(tenantAtom);
  const isMobileApp = useAtomValue(isMobileAppAtom);
  const router = useRouter();

  const redirectUrls: Record<string, string> = {
    rac: `https://motorway.co.uk/partner/rac?utm_source=rac&utm_medium=affiliate&utm_campaign=valuemycar&vrm=${reg}`,
    default: `https://motorway.co.uk/partner/heycar?utm_source=heycar&utm_medium=affiliate&utm_campaign=heycar_valuation_sell&vrm=${reg}&vrm_redirect=true`,
  };

  const usps = [motorwayRedirectUspOne, motorwayRedirectUspTwo, motorwayRedirectUspThree];
  const redirectUrl = (tenant && redirectUrls?.[tenant]) || redirectUrls.default;

  const [countdown, setCountdown] = useState(3);
  const [isModalOpen, setIsModalOpen] = useAtom(isModalOpenAtom);
  const isInitialMount = useInitialMount();

  useEffect(() => {
    if (!isInitialMount) {
      setCountdown(3);
    }
  }, [modalKey]);

  useEffect(() => {
    if (countdown > 0) {
      const timer = setInterval(() => {
        setCountdown(prevCountdown => prevCountdown - 1);
      }, 1000);

      return () => {
        clearInterval(timer);
      };
    }
    if (isModalOpen) {
      if (isMobileApp) {
        setIsModalOpen(false);
        router.push(redirectUrl);
        return;
      }

      if (window.innerWidth < 768) {
        window.location.href = redirectUrl;
        return;
      }

      window.open(redirectUrl, '_blank');
    }
  }, [countdown]);

  return (
    <div className={styles.modalWrapper}>
      <Typography variant={'h3'} className={styles.modalTitle}>
        {motorwayRedirectTimerCopy} {countdown}
        {'...'}
      </Typography>
      <Typography variant={'h6'}>{motorwayRedirectSubheader}</Typography>
      <ul className={styles.uspList}>
        {usps.map((usp, index) => {
          if (usp) {
            return (
              <li key={index} className={styles.uspListRow}>
                <TickSuccess />
                {usp}
              </li>
            );
          }
          return null;
        })}
      </ul>
      {countdown < 1 && (
        <p className={styles.redirectError}>
          <a href={redirectUrl} rel="noopener noreferrer" target="_blank">
            {motorwayRedirectErrorMessage}
          </a>
        </p>
      )}
      {motorwayRedirectLogo?.[0] && (
        <Image
          src={motorwayRedirectLogo?.[0]}
          alt="Motorway Logo"
          width={225}
          height={60}
          className={styles.logo}
        />
      )}
    </div>
  );
};
