'use client';

import { ChangeEvent, useState } from 'react';

import { getClientLocale } from '@cookies/GetClientLocale';

import { useModal } from '@core/Components/Modal/UseModal';
import { NUMBER_PLATE_REGEX } from '@core/Constants/Validations';
import { VehicleValuationData } from '@core/Entities/Vehicle/Valuation.entity';
import { useAtom } from 'jotai';

import { useRouter } from 'next/navigation';

import { tradeInAction } from 'Actions/TradeIn/TradeIn.action';

import { valuationAtom } from 'Atoms/App/Valuation/Valuation.atom';
import { PageLoader } from 'Components/PageLoader/PageLoader';

import { DetailsInputFormProps } from './DetailsInputForm.entity';

import { InputSection } from './InputSection';
import { MotorwayModal } from './MotorwayModal/MotorwayModal';

import { ValuationSection } from './ValuationSection';

import styles from './DetailsInputForm.module.css';

export const DetailsInputForm = ({
  inputFormTitle,
  inputFormCtaOptions,
  strings,
  inputFormCtaCopy,
  valuationHeader,
  valuationCopy,
  valuationPrimaryCtaCopy,
  valuationPrimaryCtaOptions,
  valuationSecondaryCtaCopy,
  valuationSecondaryCtaOptions,
  valuationFooter,
  motorwayRedirectTimerCopy,
  motorwayRedirectModalHeader,
  motorwayRedirectSubheader,
  motorwayRedirectUspOne,
  motorwayRedirectUspTwo,
  motorwayRedirectUspThree,
  motorwayRedirectErrorMessage,
  motorwayRedirectLogo,
}: DetailsInputFormProps) => {
  const [mileage, setMileage] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const router = useRouter();
  const [valuation, setValuation] = useAtom(valuationAtom);
  const [reg, setReg] = useState<string>(valuation?.vehicle.registration || '');

  const locale = getClientLocale();

  const { openModal } = useModal();

  const [modalKey, setModalKey] = useState(0);
  const handleChange = (e: ChangeEvent<HTMLInputElement>, name: 'reg' | 'mileage') => {
    if (name === 'reg') setReg(e.target.value);
    if (name === 'mileage') setMileage(e.target.value);
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!reg || !mileage || !NUMBER_PLATE_REGEX.test(reg)) {
      setIsError(true);
      return false;
    }

    setIsLoading(true);
    setIsError(false);
    const res = await tradeInAction(reg, mileage, locale);

    if (res.error) {
      setIsError(true);
    } else {
      setIsError(false);
      setValuation(res.data as VehicleValuationData);
    }

    setIsLoading(false);

    if (inputFormCtaOptions[0].codename === 'motorway') {
      openMotorwayModal();
    }
  };

  const handleNotYourCarClick = () => {
    setValuation(null);
    setReg('');
    setMileage('');
    const valuationHero = document.getElementById('valuation-hero');
    if (valuationHero) {
      const top = valuationHero.getBoundingClientRect().top;
      window.scroll({
        top,
        behavior: 'smooth',
      });
    }
  };

  const handlePrimaryCtaClick = () => {
    if (valuationPrimaryCtaOptions?.[0].codename === 'motorway') {
      openMotorwayModal();
    }
    if (valuationPrimaryCtaOptions?.[0].codename === 'plp_search') {
      router.push('/uk/autos');
    }
  };

  const handleSecondaryCtaClick = () => {
    if (valuationSecondaryCtaOptions?.[0].codename === 'motorway') {
      openMotorwayModal();
    }
    if (valuationSecondaryCtaOptions?.[0].codename === 'plp_search') {
      router.push('/uk/autos');
    }
  };

  const openMotorwayModal = () => {
    setModalKey(prevKey => prevKey + 1);
    openModal(
      <MotorwayModal
        motorwayRedirectTimerCopy={motorwayRedirectTimerCopy}
        motorwayRedirectSubheader={motorwayRedirectSubheader}
        motorwayRedirectUspOne={motorwayRedirectUspOne}
        motorwayRedirectUspTwo={motorwayRedirectUspTwo}
        motorwayRedirectUspThree={motorwayRedirectUspThree}
        motorwayRedirectErrorMessage={motorwayRedirectErrorMessage}
        motorwayRedirectLogo={motorwayRedirectLogo}
        reg={reg}
        modalKey={modalKey}
      />,
      {
        header: motorwayRedirectModalHeader,
        modalClassName: styles.modalContainer,
        modalBodyClassName: styles.modalBody,
      },
    );
  };

  const valuationProps = {
    valuationHeader,
    valuationCopy,
    valuationFooter,
    valuationPrimaryCtaCopy,
    valuationSecondaryCtaCopy,
    locale,
    strings,
    handlePrimaryCtaClick,
    handleSecondaryCtaClick,
    handleNotYourCarClick,
  };

  const inputProps = {
    inputFormTitle,
    isError,
    strings,
    reg,
    mileage,
    isLoading,
    inputFormCtaCopy,
    onSubmit,
    setIsError,
    handleChange,
  };

  return (
    <div>
      {valuation && <ValuationSection {...valuationProps} valuation={valuation} />}
      {valuation === null && <InputSection {...inputProps} />}
      {valuation === undefined && (
        <div className={styles.pageLoader}>
          <PageLoader isAbsolute />
        </div>
      )}
    </div>
  );
};
