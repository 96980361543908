/* eslint-disable @typescript-eslint/naming-convention */

import { FC } from 'react';

import cn from 'classnames';

import { getSanitizedInnerHTML } from '@core/Utils/Html/SanitizeHtml';

import styles from './RichText.module.css';

type RichTextProps = {
  richText: string;
  noPadding?: boolean;
  className?: string;
};

export const RichText: FC<RichTextProps> = ({ noPadding, richText, className }) => {
  const classNames = cn(styles.richText, noPadding && styles.noPadding, className);
  const sanitizedRichText = getSanitizedInnerHTML(richText);
  return <div className={classNames} dangerouslySetInnerHTML={sanitizedRichText} />;
};
